// https://github.com/shadcn-ui/ui/blob/main/apps/www/components/mobile-nav.tsx

'use client'

import * as React from 'react'
import { Sheet, SheetContent, SheetTrigger } from '@/app/components/ui/sheet'
import { Button } from '@/app/components/ui/button'
import { MenuIcon } from 'lucide-react'
import { ScrollArea } from '@/app/components/ui/scroll-area'
import ChatHistory from '@/app/components/chat-history'

export function SidebarMobile() {
  const [open, setOpen] = React.useState(false)

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
        >
          <MenuIcon />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="px-0">
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
          <ChatHistory onChange={() => setOpen(false)} />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}
