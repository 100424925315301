'use client'

import { ScrollArea } from '@/app/components/ui/scroll-area'
import ChatHistory from '@/app/components/chat-history'

export default function SidebarDesktop() {
  return (
    <div className="bg-white rounded-r-md h-screen border border-gray-100">
      <ScrollArea className="h-full py-2 px-2 lg:py-4">
        <ChatHistory />
      </ScrollArea>
    </div>
  )
}
