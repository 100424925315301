import { BankingFinanceDisclaimer } from '@/app/components/disclaimer-content/content-bankingfinance'
import { EFundamentalsDisclaimer } from '@/app/components/disclaimer-content/content-efundamentals'

const disclaimersMap = {
  BankingFinanceDisclaimer,
  EFundamentalsDisclaimer,
} as const

export type DisclaimerName = keyof typeof disclaimersMap

export default disclaimersMap
