import(/* webpackMode: "eager", webpackExports: ["default"] */ "/github/workspace/apps/frontend/app/components/chat-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/github/workspace/apps/frontend/app/components/disclaimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/github/workspace/apps/frontend/app/components/sidebar-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatProvider"] */ "/github/workspace/apps/frontend/context/chat-context.tsx");
;
import(/* webpackMode: "eager" */ "/github/workspace/apps/frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/github/workspace/apps/frontend/app/globals.css");
