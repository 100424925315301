import { parseCookies, setCookie, destroyCookie } from 'nookies'

export class CookieService {
  static CONSENT_COOKIE_NAME = 'user-consent'
  static CONSENT_COOKIE_TTL_DAYS = 7

  static setIsConsented(isConsented: boolean): void {
    // days to seconds conversion
    const maxAge = 60 * 60 * 24 * this.CONSENT_COOKIE_TTL_DAYS
    setCookie(null, this.CONSENT_COOKIE_NAME, String(isConsented), {
      maxAge,
      path: '/',
      sameSite: process.env.NODE_ENV === 'production' ? 'none' : 'strict',
      secure: process.env.NODE_ENV === 'production',
    })
  }

  static getIsConsented(): boolean {
    const cookies = parseCookies(null)
    const consentCookie = cookies[this.CONSENT_COOKIE_NAME]
    return consentCookie ? consentCookie === 'true' : false
  }

  static clearConsent(): void {
    destroyCookie(null, this.CONSENT_COOKIE_NAME, { path: '/' })
  }
}
