'use client'

import React from 'react'

interface UsageRenewTooltipProps {
  renewTimestamp: string
}

export default function UsageRenewText({
  renewTimestamp,
}: UsageRenewTooltipProps) {
  const renewDate = new Date(renewTimestamp)
  const formattedDate = renewDate.toLocaleString('en-GB', {
    month: 'long',
    day: 'numeric',
  })

  const [day, month] = formattedDate.split(' ')
  const newFormattedDate = `${month} ${day}`
  return (
    <div className="text-xs text-center">
      Credits renew on {newFormattedDate}
    </div>
  )
}
