import { Progress } from '@/app/components/ui/progress'
import { Coins } from 'lucide-react'
import UsageRenewText from '@/app/components/usage-renew'

export default function UsageDisplay({
  percentage,
  renewTimestamp,
}: {
  percentage: number
  renewTimestamp: string | null
}) {
  return (
    <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-2 w-full order-2 md:order-1">
      <div className="flex items-center">
        <span className="inline text-sm">
          {1000 - Math.round(percentage * 10)} / 1000
        </span>
        <Coins className="w-4 h-4 mx-2" />
      </div>
      <div className="flex-row items-center w-48">
        <Progress value={100 - percentage} className="w-full h-2" />
        {renewTimestamp && <UsageRenewText renewTimestamp={renewTimestamp} />}
      </div>
    </div>
  )
}
