'use client'

import { Button } from '@/app/components/ui/button'
import { useRouter } from 'next/navigation'
import { useChatContext } from '@/context/chat-context'
import { PlusSquareIcon } from 'lucide-react'
import * as React from 'react'
import { nanoid } from 'ai'
import { cn } from '@/app/components/ui/lib/utils'

export default function ChatHistory({ onChange }: { onChange?: () => void }) {
  const router = useRouter()
  const { chatId, chats, setChatId } = useChatContext()

  const setActiveChat = async (langfuseSessionId: string) => {
    router.push(`/${langfuseSessionId}`)
    onChange?.()
  }

  const newChat = async () => {
    // need to set a new chatId to reset chat-section messages to initialMessages
    setChatId(nanoid())
    router.push(`/`)
    onChange?.()
  }

  return (
    <div className="flex flex-col space-y-2 px-4 pr-8 md:pr-4">
      <div className="flex justify-between items-center">
        <h2 className="font-bold w-fit">Chat History</h2>
        <Button
          variant="ghost"
          className="px-0 hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => newChat()}
        >
          <PlusSquareIcon />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </div>
      {chats.length ? (
        chats.map((chat) => (
            <Button
              key={chat.id}
              variant={'link'}
              className={cn('w-fit px-0', chatId == chat.langfuseId ? 'font-bold' : '')}
              onClick={() => setActiveChat(chat.langfuseId)}
            >
              {chat.title}
            </Button>
        ))
      ) : (
        <div className="p-2 text-sm">Nothing to show here...</div>
      )}
    </div>
  )
}
